<template>
  <v-dialog v-model="dialog" persistent fullscreen scrollable transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" fab v-bind="attrs" v-on="on" height="50px" width="50px">
        <v-icon color="white">mdi-home-city-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" height="50px">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Información de pago lote residencial</v-toolbar-title>
        <!-- <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false"> Save </v-btn>
        </v-toolbar-items> -->
      </v-toolbar>
      <v-card-text>
        <MonthPlanForm :customerId="customerId" />
      </v-card-text>
      <!-- <v-card-actions>
        <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn>
        <v-btn color="blue darken-1" text @click="dialog = false"> Save </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import MonthPlanForm from '@/components/MonthPlanForm.vue';
export default {
  components: {
    MonthPlanForm,
  },
  props: {
    customerId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>