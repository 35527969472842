<template>
  <div style="height: 90vh">
    <!-- <p class="titleLote">Información de pago lote residencial</p> -->
    <div style="margin-bottom: 50px">
      <v-subheader style="margin-top: 50px" class="title-modal">Pago inicial (enganche)</v-subheader>
      <v-simple-table dense>
        <thead>
          <tr>
            <th id="desc" class="text-left title-table-col">Descripción</th>
            <th id="import" class="text-left title-table-col">Importe</th>
            <th id="date" class="text-left title-table-col">Fecha del pago</th>
            <th id="date2" class="text-left title-table-col">Creado</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="width: 30%; padding-top: 25px">
              <v-text-field v-model="initialConcept" dense solo label="Descripción"></v-text-field>
            </td>
            <td style="padding-top: 25px">
              <v-text-field v-model="initialPayment" prefix="$" label="Importe" solo dense></v-text-field
            ></td>
            <td style="padding-top: 25px"><DatePicker @change="initialDate = $event" /></td>
            <td></td>
            <td>
              <v-btn @click="savePaymentInitial()" tile color="primary">
                <v-icon> mdi-content-save </v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-for="(enganche, i) in initialPayments" :key="i" class="text-fields-row">
            <td>{{ enganche.concept }}</td>
            <td>{{ $currency.format(enganche.amount) }} </td>
            <td>{{ enganche.paymentDate }}</td>
            <td>{{ dayjs(enganche.createdAt).format('llll') }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div style="display: flex">
      <v-subheader class="title-modal">Pagos mensuales</v-subheader>

      <v-spacer></v-spacer>
      <div v-if="!existPayments && !loading">
        <v-btn v-if="!optionalPayments.length" @click="generateModelPayments" class="primary"
          >Generar modelo de pagos</v-btn
        >
        <v-row v-else>
          <v-btn @click="saveModelPayments" class="primary">Guardar modelo de pagos</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="optionalPayments = []" class="warning">Borrar modelo de pagos</v-btn>
        </v-row>
        <v-form v-model="validForGenerateModelPayment">
          <v-container>
            <v-row>
              <v-col cols="12" md="2">
                <v-select v-model="modelPymentData.startYear" :items="numbersYear" label="Año"></v-select>
                <!-- <v-text-field v-model="modelPymentData.startYear" type="number" label="Año" required></v-text-field> -->
              </v-col>

              <v-col cols="12" md="2">
                <v-select v-model="modelPymentData.startMonth" :items="numbersMonth" label="Mes de inicio"></v-select>
                <!-- <v-text-field
                  v-model="modelPymentData.startMonth"
                  type="number"
                  label="Mes de inicio"
                  required
                ></v-text-field> -->
              </v-col>

              <v-col cols="12" md="2">
                <v-select v-model="modelPymentData.paymentDueDay" :items="numbersDay" label="Día de cobro"></v-select>
                <!-- <v-text-field
                  v-model="modelPymentData.paymentDueDay"
                  type="number"
                  label="Día de cobro"
                  required
                ></v-text-field> -->
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model="modelPymentData.amount" type="number" label="Total" required></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="modelPymentData.numberMonthlyPayments"
                  type="number"
                  label="Números de pagos"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th id="lote-1" class="text-left title-table-col">Descripción</th>
          <th id="lote-2" class="text-left title-table-col">Importe</th>
          <th id="lote-3" class="text-left title-table-col">Fecha de vencimiento</th>
          <th id="lote-4" class="text-left title-table-col">Recargo</th>
          <th id="lote-5" class="text-left title-table-col">Condonar recargo</th>
          <th id="lote-6" class="text-left title-table-col">Estatus</th>
        </tr>
      </thead>
      <tbody>
        <!-- { "concept": "Cuota 1 de 20", "serie": 1, "paymentDueDate": "15-10-2021", "amount": "250.00" } -->
        <tr>
          <td></td>
        </tr>
        <tr v-for="(textField, i) in optionalPayments" :key="i" class="text-fields-row">
          <td style="width: 20%">
            <span>{{ textField.concept }}</span>
          </td>
          <td style="width: 15%">
            <span>{{ $currency.format(textField.amount) }}</span>
          </td>
          <td style="width: 15%">
            {{ textField.paymentDueDate }}
          </td>
          <td v-if="textField.id" style="width: 15%">
            {{ $currency.format(textField.surcharges) }}
          </td>
          <td v-else>-</td>
          <td v-if="textField.id" style="width: 5%; padding-left: 35px; width: 10%">
            <v-checkbox
              :disabled="textField.surcharges == 0 || textField.status === 'paid' || textField.status === 'canceled'"
              v-model="textField.forgiveSurcharges"
              @click="alertcondone(textField)"
            />
          </td>
          <td v-else>-</td>
          <td v-if="textField.id" style="width: 15%">
            {{ textField.status }}
          </td>
          <td v-else>No pagado</td>

          <td v-if="textField.id" style="width: 5%">
            <v-btn
              :disabled="['paid', 'canceled'].includes(textField.status)"
              @click="alertregisterpayment(textField)"
              tile
              color="success"
            >
              <v-icon> mdi-cash-check </v-icon>
            </v-btn>
          </td>
          <td v-else>-</td>

          <td v-if="textField.id" style="width: 5%">
            <v-btn
              :disabled="['paid', 'canceled'].includes(textField.status)"
              @click="alertcancelpayment(textField)"
              tile
              color="error"
            >
              <v-icon> mdi-cancel </v-icon>
            </v-btn>
          </td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import adminPayment from '@/services/admin/monthlyPayments';
import DatePicker from '@/components/DatePicker.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  components: {
    DatePicker,
  },
  props: {
    customerId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      validForGenerateModelPayment: true,
      dayjs,
      textFields: [],
      items: ['pending', 'paid', 'canceled'],
      initialConcept: '',
      initialPayment: '',
      initialDate: dayjs(Date.now()).format('YYYY-MM-DD'),
      initialPayments: [],
      optionalPayments: [],
      modelPymentData: {
        startYear: dayjs(Date.now()).format('YYYY'),
        startMonth: dayjs(Date.now()).format('MM'),
        paymentDueDay: '05',
        amount: '0',
        numberMonthlyPayments: '0',
      },
    };
  },
  mounted() {
    this.getInitialPayments();
    this.fetchPayments();
  },
  computed: {
    existPayments() {
      return this.optionalPayments.some((item) => Object.prototype.hasOwnProperty.call(item, 'id'));
    },
    numbersDay() {
      return Array.from({ length: 28 }, (_, i) => (i + 1 < 10 ? `0${i + 1}` : i + 1 + ''));
    },
    numbersYear() {
      return Array.from({ length: 50 }, (_, i) => i + 2021 + '');
    },
    numbersMonth() {
      return Array.from({ length: 12 }, (_, i) => (i + 1 < 10 ? `0${i + 1}` : i + 1 + ''));
    },
  },
  methods: {
    add() {
      this.generateModelPayments();
    },
    savePaymentInitial() {
      const amount = this.initialPayment * 100;
      if (!Number.isInteger(amount)) {
        this.$swal('Enganche', 'El enganche debe tener a lo más dos decimales.', 'warning');
        return;
      }
      const data = {
        customerId: this.customerId,
        concept: this.initialConcept,
        paymentDate: dayjs(this.initialDate).format('YYYY-MM-DD'),
        amount,
      };
      if (!data.concept) {
        this.$swal({
          title: 'Campo requerido',
          text: 'Debes agregar una descripción del pago de enganche.',
          icon: 'warning',
          showCancelButton: false,
        });
        return;
      }
      if (!data.paymentDate) {
        this.$swal({
          title: 'Campo requerido',
          text: 'Debes agregar una fecha del pago de enganche.',
          icon: 'warning',
          showCancelButton: false,
        });
        return;
      }
      if (!data.amount) {
        this.$swal({
          title: 'Campo requerido',
          text: 'Debes agregar el monto del pago de enganche.',
          icon: 'warning',
          showCancelButton: false,
        });
        return;
      }
      adminPayment
        .createPaymentInitial(data)
        .then(() => {
          this.initialConcept = '';
          this.initialPayment = '';
          this.initialDate = dayjs(Date.now()).format('YYYY-MM-DD');
          this.getInitialPayments();
          this.$swal({
            title: 'Enganche creado',
            text: 'Enganche agregado exitosamente.',
            icon: 'success',
            showCancelButton: false,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.$swal('Error al actualizar enganche', error.response.data.message, 'warning');
          }
        });
    },

    getInitialPayments() {
      this.loading = true;
      adminPayment.fetchByUserIdPaymentInitial(this.customerId).then((response) => {
        this.initialPayments = response.data;
        this.loading = false;
      });
    },

    actionsPay(item, action) {
      this.loading = true;
      adminPayment[action](item.id).then(() => {
        this.fetchPayments();
        let message = '';
        if (action === 'payByIdPayment') {
          message = 'Se marco como pagado el mes seleccionado';
        }
        if (action === 'cancelByIdPayment') {
          message = 'Se cancelo el pago del mes seleccionado';
        }
        if (action === 'cancelSurchargesByIdPayment') {
          message = 'Se condonaron los recargos del mes seleccionado';
        }
        this.$swal('Operación realizada', message, 'success');
        this.loading = false;
      });
    },

    generateModelPayments() {
      const amount = this.modelPymentData.amount * 100;
      if (amount < 1) {
        this.$swal({
          title: 'Campo requerido',
          text: 'El cobro debe ser un número mayor a cero.',
          icon: 'warning',
          showCancelButton: false,
        });
        return;
      }
      if (this.modelPymentData.numberMonthlyPayments < 1) {
        this.$swal({
          title: 'Campo requerido',
          text: 'El número de cobros debe ser mayor a cero.',
          icon: 'warning',
          showCancelButton: false,
        });
        return;
      }
      if (!Number.isInteger(amount)) {
        this.$swal('Cobro de mensualidad', 'El cobro de mensualidad debe tener a lo más dos decimales.', 'warning');
        return;
      }

      const data = {
        startYear: this.modelPymentData.startYear,
        startMonth: this.modelPymentData.startMonth,
        paymentDueDay: this.modelPymentData.paymentDueDay,
        amount,
        numberMonthlyPayments: this.modelPymentData.numberMonthlyPayments,
      };
      adminPayment
        .generate(data)
        .then((response) => {
          this.optionalPayments = response.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.$swal('Error al generar esquema de pagos', error.response.data.message, 'warning');
          }
        });
    },

    fetchPayments() {
      adminPayment.search(this.customerId).then((response) => {
        this.optionalPayments = response.data;
      });
    },

    saveModelPayments() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que la generación de forma de pagos es realizada solo por unica ocasión',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, generar pagos!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          adminPayment
            .createPayment(this.customerId, this.optionalPayments)
            .then(() => {
              this.fetchPayments();
              this.$swal({
                title: 'Mensualidades creadas',
                text: 'Mensualidades agregados exitosamente.',
                icon: 'success',
                showCancelButton: false,
              });
            })
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                this.$swal('Error al crear pagos', error.response.data.message, 'error');
              }
            });
        } else {
          this.$swal('Operación cancelada', 'No se genero el esquema de pagos', 'error');
        }
      });
    },

    remove(index) {
      this.textFields.splice(index, 1);
    },

    alertcondone(textField) {
      // Use sweetalert2
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que al condonar el pago de recargo este será $0 y no habrá forma de reajustarlo',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡condonar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.actionsPay(textField, 'cancelSurchargesByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se condonaron los recargos del mes seleccionado', 'error');
        }
      });
    },

    alertregisterpayment(textField) {
      // Use sweetalert2
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que al registrar un pago no habrá forma de cambiar el estatus nuevamente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡registrar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.actionsPay(textField, 'payByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se registró el pago del usuario', 'error');
        }
      });
    },

    alertcancelpayment(textField) {
      // Use sweetalert2
      this.$swal({
        title: '¿Deseas cancelar el pago?',
        text: 'Al cancelar el pago no habrá forma de cambiar el estatus nuevamente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡cancelar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.actionsPay(textField, 'cancelByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se realizó la cancelación del pago', 'error');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>