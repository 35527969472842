import { apiClient } from '../index';

/**
 * [user description]
 * @return {[type]}          [description]
 */
function getUsers(includeAdmin = false) {
  const params =  {}
  if (includeAdmin) params.admin = true;
  
  return apiClient.get('/v1/users/all', { params });
}

/**
 * [user description]
 * @return {[type]}          [description]
 */
 function updateUserPerfil(id, data) {
  return apiClient.put('/v1/users/profile/' + id, data);
}

/**
 * [user description]
 * @return {[type]}          [description]
 */
 function searchUsers(filter = null) {
  const params =  {}
  params.admin = false;
  if ( filter != null && filter != undefined && filter != '')  params.filter = filter;

  return apiClient.get('/v1/users/all', { params });
}

export default { getUsers, updateUserPerfil, searchUsers };