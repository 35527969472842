<template>
  <v-app id="inspire">
    <v-navigation-drawer app width="225" color="primary" class="pl-2" style="padding-top: 25px">
      <v-img max-width="205" src="@/assets/logo.png"></v-img>
      <v-list>
        <v-list-item-group mandatory>
          <div v-for="(menu, i) in menu" :key="i">
            <v-list-item
              v-if="!menu.subLinks"
              style="margin-top: 15px"
              link
              :to="{ name: menu.name }"              
              :key="i"
              active-class="black"
            >
              <v-list-item-icon>
                <v-icon color="white" v-text="menu.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text" v-text="menu.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group v-else >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon class="white--text" v-text="menu.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="white--text" v-text="menu.text"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(subLink, j) in menu.subLinks"
                :key="j"
                style="margin-top: 15px; margin-left: 15px;"
                link
                :to="{ name: subLink.name }"  
              >                  
                <v-list-item-content>
                  <v-list-item-title class="white--text" v-text="subLink.text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="white" v-text="subLink.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </div>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="white">mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text" @click="logout">Cerrar sesión </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer app clipped right>
      <v-card-text>
        <v-row align="center">
          <v-col cols="3">
            <v-avatar color="red">
              <span class="white--text text-h5">{{ getInitials }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="9">
            <v-list-item-content>
              <v-list-item-title class="title-user-scr-b">
                {{ user.usernameLogin }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-card-text>

      <v-row>
        <div class="font-weight-medium" style="padding: 25px 0px 25px 25px">
          Últimos pagos recibidos
         
          <v-btn icon style="margin-left: 15px">            
            <v-icon dark> mdi-refresh </v-icon>
          </v-btn>
         
        </div>
      </v-row>
      <v-divider> 
      </v-divider>
       <LastPaymentsList />
      <v-list two-line>
        <div v-for="(income, index) in monthly.slice(0, 6)" :key="index">
          <v-list-item :key="index">
            <v-list-item-avatar>
              <img alt="" :src="getUserAvatar(income.customerId) || imageDefaul" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ getUserInfo(income.customerId) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle style="white-space: nowrap; overflow: hidden">{{
                $currency.format(income.amount)
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list>
              <img
                v-if="income.paymentMethod === 'cash'"
                alt=""
                style="width: 40px; margin-left: 10px"
                src="@/assets/cash.png"
              />
              <img v-else alt="" style="width: 40px; margin-left: 10px" src="@/assets/conekta.png" />
            </v-list>
          </v-list-item>
        </div>
      </v-list>

      <v-divider></v-divider>

      <v-card-text style="padding: 50px 30px 30px 30px">
        <v-row align="center">
          <Modal />
        </v-row>
      </v-card-text>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>


<script>
import Modal from '@/components/Modal.vue';
import LastPaymentsList from '@/components/LastPaymentsList.vue';

import adminUserService from '@/services/admin/users';
import adminMonthlyService from '@/services/admin/monthlyPayments';

export default {
  components: {
    Modal,
    LastPaymentsList
  },
  computed: {
    user() {
      return this.$store.getters['session/user'];
    },
    getInitials() {
      var names = this.user.usernameLogin.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },

    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    
  },
  data: () => ({
    scheduleSelected: null,
    income: [],
    monthly: [],
    selectedItem: 1,
    loading: false,
    menu: [
      { text: 'Dashboard', icon: 'mdi-view-grid', name: 'dashboardAdmin' },
      { text: 'Usuarios', icon: 'mdi-account', name: 'users',
        subLinks : [
              { text: 'Socios', icon: 'mdi-account', name: 'users' },
              { text: 'Boletines', icon: 'mdi-newspaper', name: 'newsletter' },
              { text: 'Reservaciones', icon: 'mdi-calendar-today', name: 'booking' },
              { text: 'Notificaciones', icon: 'mdi-send', name: 'notifications' },
              { text: 'Tickets', icon: 'mdi-ticket', name: 'tickets' },
          ]
      },   
      { text: 'Ingresos', icon: 'mdi-cash-multiple', name: 'income' },            
      { text: 'Egresos', icon: 'mdi-cash-plus', name: 'spend' },       
      { text: 'Configuraciones', icon: 'mdi-wrench', name: 'settings' },
      { text: 'Reporte Cuotas', icon: 'mdi-grid', name: 'reports' },      
    ],
  }),
  created() {
    this.getUsers();
  },
  methods: {
    logout() {
      this.$store.dispatch('session/logout');
    },
    getUserInfo(userId) {
      if (!this.users) {
        return '';
      }
      const user = this.users.filter((item) => item.id === userId)[0];
      return user.usernameLogin;
    },
    getUserAvatar(userId) {
      if (!this.users) {
        return '';
      }
      const user = this.users.filter((item) => item.id === userId)[0];
      if (!user) return null;
      return user.imageUrl;
    },
    getUsers() {
      adminUserService.getUsers('all').then((response) => {
        this.users = response.data;
        this.fetchMonthly();
      });
    },
    usernameLogin(user) {
      return `${user.usernameLogin} [${user.email}]`;
    },
    fetchMonthly() {
      const startDate = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      const endDate = this.$dayjs().format('YYYY-MM-DD');

      this.loading = true;
      adminMonthlyService
        .search(null, 'paid', startDate, endDate)
        .then((response) => {
          this.monthly = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>