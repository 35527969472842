<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small fab dark v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <span class="text" style="padding-top: 10px; padding-left: 15px"> Agregar nuevo usuario </span>
      </template>
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-card>
          <div class="padding-card">
            <p class="title-modal">Registrar usuario</p>
            <UserForm ref="newUser" :info-user="{}" @ready-to-save="readyToSave = $event" />
          </div>
          <v-card-actions>
            <!-- <v-btn :disabled="!validForm" @click="validate" depressed color="#00aff2" dark> Guardar usuario </v-btn> -->
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
            <v-btn :disabled="!validForm" @click="validate" color="blue darken-1" text> Guardar usuario </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import UserForm from '@/components/NewUserForm.vue';

export default {
  name: 'ModalUser',
  components: {
    UserForm,
  },
  data: () => ({
    validForm: true,
    dialog: false,
    readyToSave: false,
  }),

  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.$refs.newUser.checkPassword()) { 
          const user = this.$refs.newUser.userData();
          this.$emit('save', user);
        } else {
          this.$swal('Contraseñas no coinciden', 'Verifique que las contraseñas coincidan e intente nuevamente', 'warning');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>